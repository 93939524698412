<template>
  <v-row
    v-show="hasPendingPlanChange"
  >
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>
          You have requested a change to the {{ planName }}
          internet plan. To activate this plan, please complete a payment of Ksh
          {{ amountToTopUp | formatCurrency }}.
        </p>
        <v-btn
          color="error"
          class="ml-2"
          :loading="isCancelingPlanChange"
          @click="cancelPlanChange"
        >
          <v-icon left>
            {{ icons.cancel }}
          </v-icon>
          Cancel Plan Change
        </v-btn>
        <v-btn
          color="success"
          class="ml-2"
          @click="payForPlanChange"
        >
          <v-icon left>
            {{ icons.cash }}
          </v-icon>
          Pay Now
        </v-btn>
      </v-alert>
    </v-col>
    <mpesa-payment-dialog
      :show-dialog="showMpesaPaymentDialog"
      :amount="parseFloat(pendingPlanChange.amount_to_top_up)"
      :contact-number="pendingPlanChange.phone_number"
      @close="showMpesaPaymentDialog = false"
    ></mpesa-payment-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiInformationOutline, mdiCashMultiple, mdiCancel } from '@mdi/js'
import MpesaPaymentDialog from '@/components/dialogs/InitiateMpesaPaymentDialog.vue'

export default {
  components: {
    MpesaPaymentDialog,
  },
  data() {
    return {
      showMpesaPaymentDialog: false,
      pendingPlanChange: {
        new_plan: {
          name: '',
        },
        amount_to_top_up: 0,
        phone_number: '',
        id: null,
      },
      hasPendingPlanChange: false,
      isCancelingPlanChange: false,
      icons: {
        info: mdiInformationOutline,
        cash: mdiCashMultiple,
        cancel: mdiCancel,
      },
    }
  },
  computed: {
    planName() {
      return this.pendingPlanChange?.new_plan?.name || 'the selected'
    },
    amountToTopUp() {
      return this.pendingPlanChange?.amount_to_top_up || 0
    },
  },
  mounted() {
    if (process.env.VUE_APP_HOTSPOT_USER_MUST_REGISTER === 'true') {
      this.checkForPendingPlanChange()
    }
  },
  methods: {
    checkForPendingPlanChange() {
      axios.get('/customer/pending-plan-change')
        .then(response => {
          if (response.data.length !== 0) {
            this.pendingPlanChange = response.data
            this.hasPendingPlanChange = true
          } else {
            this.hasPendingPlanChange = false
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    payForPlanChange() {
      this.showMpesaPaymentDialog = true
    },
    cancelPlanChange() {
      this.isCancelingPlanChange = true
      axios.post(`customer-plans/${this.pendingPlanChange.id}/cancel`)
        .then(() => {
          this.hasPendingPlanChange = false
          this.$toast.success('Plan change cancelled successfully')
        })
        .catch(error => {
          console.error('Error cancelling plan change:', error)
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isCancelingPlanChange = false
        })
    },
  },
}
</script>
